// Links and hovers
.link-blue {
  color: #2187fb;
  &:hover {
    color: #333333;
    text-decoration: underline;
  }
}
.link-dark,
.link-dark-underlined {
  color: #333333;
}
.link-dark-underlined {
  text-decoration: underline;
}
.link-dark:hover,
.link-dark-underlined:hover {
  color: #2187fb !important;
  text-decoration: none;
}

.hover-text-blue:hover {
  color: #2187fb !important;
}
.hover-blue:hover {
  background: #2187fb !important;
  color: #f2f2f2 !important;
}
.hover-gray:hover {
  background: #f2f2f2 !important;
  color: #333 !important;
}
.hover-red:hover {
  background: #e03232;
  border-color: #de2727;
  color: #f2f2f2;
}
.hover-white:hover {
  color: #fff;
}
.color-blue {
  color: #2187fb !important;
}

.shadow {
  box-shadow: 0px 12px 23px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 12px 23px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 12px 23px -4px rgba(0, 0, 0, 0.75);
}
