@import "/src/scss/variables";

@mixin flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.header {
  @include flex;
  background: $gray-1 !important;
  border: none !important;

  .left-side,
  .right-side {
    @include flex;
  }

  .greet {
    font-size: 1.2rem;
    span {
      font-weight: 300;
      color: $gray-3;
    }
    b {
      font-weight: 600;
      color: $black;
    }
  }
}
