// Calendar
.custom-calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  &::after {
    content: "";
    flex: auto;
  }
}

.react-calendar {
  border: 1px solid #f0f0f0 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}
.react-calendar__month-view__days {
  pointer-events: none !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__month-view__days__day--neighboringMonth .total-checks {
  color: #757575 !important;
  border-color: #757575 !important;
}
.react-calendar__tile--now {
  background: #fff !important;
}
