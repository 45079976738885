// If you want to override variables do it here
@import "variables";
@import "utils";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui";

@import "./calendar.scss";
@import "./tables.scss";
@import "./sidebar.scss";

// If you want to add something do it here
@import "custom";
@import "overrides";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;

  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
