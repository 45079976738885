@import "/src/scss/variables";

.name-email-input {
  width: fit-content;

  .input-group-append,
  .input-group-text {
    background: $pure-white !important;
  }

  .form-control {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .input-group-text {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .form-control,
  .input-group-text {
    border-color: $pale-blue !important;
  }

  .form-control {
    border-right: none !important;
  }
}
