@import "/src/scss/variables";

.my_sidebar {
  background: $blue !important;
  background: linear-gradient(
    0deg,
    $blue 0%,
    $blue 10%,
    $pure-white 60%,
    $pure-white 100%
  ) !important;

  .brand {
    background: none !important;
    justify-content: start;
    padding: 0.7rem 0.9rem;
    img {
      height: 30px;
    }
    svg {
      color: $black !important;
    }
  }

  .sidebar_nav {
    a,
    li,
    .c-active:hover {
      color: $black !important;
      svg {
        color: $black !important;
      }
    }

    & a:hover {
      color: $white !important;
      svg {
        color: $white !important;
      }
    }

    .c-active {
      background: rgba($pure-black, 0.05) !important;
    }
  }

  .c-sidebar-minimizer {
    background: $blue !important;
  }

  .c-sidebar-minimizer::before {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 11 14%27%3E<path fill=%27%23f2f2f2%27 d=%27M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z%27/%3E%3C/svg>") !important;
  }
}
