// Colors

$blue: #2187fb;
$black: #333333;
$white: #f2f2f2;

$pure-white: #fff;
$pure-black: #000;

$red: #ff3b30;
$green: #59a544;
$yellow: #f2b432;

$gray-1: #f9f9f9;
$gray-2: #fafafa;
$gray-3: #8d8d8d;
$gray-4: #eeeeee;

$pale-blue: #cadcfc;

$blue2: #3a8bfa;
$green2: #3b8855;
