@import "/src/scss/variables";

.filter_button {
  display: flex !important;
  gap: 5px !important;

  border: 1px solid $pale-blue !important ;
  background: none !important;

  color: $pure-black !important;
  border-radius: 6px !important;

}

.filter_button:hover {
  color: $blue !important;
}
