@import "/src/scss/variables";

.buttons {
  display: flex !important;
  gap: 5px !important;
  min-width: 70px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  background: none !important;
  align-items: center;
  padding: 0.25rem 0.5rem !important;
}

.editButton {
  &.bordered {
    border-color: $blue2 !important;
  }
  color: $blue2 !important;
}

.editButton:hover {
  background: $blue2 !important;
  color: $white !important;
}

.deleteButton {
  &.bordered {
    border-color: $red !important;
  }
  color: $red !important;
}

.deleteButton:hover {
  background: $red !important;
  color: $white !important;
}
