.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-20 {
  gap: 20px;
}
.pointer {
  cursor: pointer !important;
}
