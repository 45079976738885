.profile-dropdown-toggle {
  padding: 3px;
  margin: 5px 1rem 5px 12px;
}

#dropdown-profile .dropdown-item:hover {
  background: #2187fb;
}
#dropdown-profile .dropdown-item:active {
  background: #074894;
}

#dropdown-profile {
  margin-top: 1rem !important;
  margin-right: 0.3rem !important;
  border: none;
  background-color: #333333;
  border-radius: 0.5rem;

  box-shadow: -9px 9px 5px -7px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: -9px 9px 5px -7px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: -9px 9px 5px -7px rgba(51, 51, 51, 0.5);

  color: #f2f2f2;
  text-align: center;
  min-width: 200px;
}

#dropdown-profile:after {
  content: " ";
  border-bottom: 14px solid #333333;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  right: 5px;
  top: -11px;
}
#dropdown-profile .title {
  font-weight: 600;
  margin-bottom: 0;
}
#dropdown-profile .subtitle {
  font-size: 0.7rem;
  opacity: 0.7;
  padding: 0 1rem;
  margin: 10px 0;
}
#dropdown-profile .dropdown-item {
  color: #f2f2f2;
}

.profile-dropdown-toggle:focus {
  box-shadow: none !important;
}

.profile-dropdown-toggle::after {
  display: none !important;
}
