.custom-table {
  .table-collapse {
    cursor: default;
    border-bottom: 1px solid #bdbdbd;
  }

  .table-row {
    cursor: pointer;
    background: #fff !important;
    &:hover {
      background: #2187fb !important;
      color: #f2f2f2 !important;
    }
    &.row-alert:hover {
      background: #ffe74765 !important;
      color: #2187fb !important;
    }
  }

  .row-alert {
    cursor: pointer;
    background: #ffe74765 !important;
    border-top: 1px solid #ffe432b8;
    border-bottom: 1px solid #ffe432b8;
    &:hover {
      color: #2187fb;
    }
  }
  .row-danger {
    cursor: pointer;
    background: #ff3b3065 !important;
    border-top: 1px solid #ff3b3065;
    border-bottom: 1px solid #ff3b3065;
    &:hover {
      color: #f2f2f2;
    }
  }
  td {
    border: none;
  }
  tr td {
    vertical-align: middle;
  }
}

// Care Circles table
.circles-container {
  .circles-row {
    background: #fff;
    .row {
      border-bottom: 1px solid #e8e8e8;
      height: 45px;
      vertical-align: middle;
      padding: 0 1rem;
    }
  }
}
