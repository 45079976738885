// Sidebar
.page-item.active .page-link {
  background: rgb(58, 139, 250);
  border-color: rgb(58, 139, 250);
}
.c-sidebar-nav-link:hover {
  background: darken(#2187fb, 10%) !important;
}
.c-sidebar-nav-title,
.c-sidebar-nav-link,
.c-sidebar-nav-icon {
  color: #f2f2f2 !important;
}

.c-sidebar-minimized .c-sidebar-nav {
  overflow: scroll;
  scrollbar-width: none;
}
.c-sidebar-minimized .c-sidebar-nav::-webkit-scrollbar {
  display: none;
}
.c-sidebar-minimizer {
  background-color: #1a6ccd !important;
}
