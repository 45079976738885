// Override library CoreUI main styles
.card {
  background-color: $pure-white;
  .card-header {
    background-color: $pure-white;
  }
}

.modal-content {
  background: $pure-white;
}

.btn-primary {
  background: $blue;
  border-color: $blue;
}

.btn.btn-primary.disabled {
  background-color: #b9bec7 !important;
  border-color: #b9bec7 !important;
  color: #3d434d !important;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
}

.c-body {
  background: $gray-1 !important;
}

.form-control {
  color: #333;
  background: $pure-white;
}
.form-control:focus {
  color: #000;
  border: none;
  background: $pure-white;
  box-shadow: 0 0 0 0.2rem rgba($blue, 0.5);
}
.input-group-append,
.input-group-prepend {
  .input-group-text {
    background: $pure-white;
  }
}

.card {
  border: none !important;
  border-radius: 15px;
}
