.new-custom-table-container {
  .new-custom-table {
    color: #333;

    .new-table-row {
      background: #fff;

      &.customHover {
        cursor: pointer;
        &:hover {
          background: #2187fb;
          color: #f2f2f2;
        }
      }

      td {
        vertical-align: middle;
        border-top-color: #e8e8e8;
      }
    }

    th {
      font-size: 12px;
      text-transform: uppercase;
      color: #a3a3a3 !important;
      border: none;
    }
  }
}

.custom_pagination {
  width: 100%;
  text-align: center;

  input {
    background: none;
    border: 1px solid #aad1ff;
    border-radius: 0.5rem;
    font-size: 13px;
    font-weight: 600;
    width: 45px;
    height: 28px;
    text-align: center;
    margin: 0 1rem;
  }

  button {
    border: none;
    width: 28px;
    height: 28px;
    background: #e7f2ff;
    border-radius: 50%;

    &:first-child {
      img {
        transform: rotate(180deg);
      }
    }

    &:focus {
      outline: none;
    }

    &:not([class^="disabled"]):hover {
      background: #d6e8fd;
    }
    &:not([class^="disabled"]):active {
      background: #b9d8fe;
    }

    &.disabled {
      background: #dfdfdf;
      opacity: 0.5;
    }
  }
}
