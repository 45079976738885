.frequency-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .frequency-card {
    flex: 1 0 220px;
  }

  &.small-card {
    .frequency-card {
      flex: 1 0 120px;
    }
  }
}
